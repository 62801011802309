import { createPolymorphicComponent, Stack, Text, ButtonCard, ButtonCardProps } from 'ui/core'
import { forwardRef } from 'react'
import { Image } from 'core/components'

export interface CategoryCardProps extends Omit<ButtonCardProps, 'children'> {
  image?: string,
  title?: string,
  imageWidth?: number,
  imageHeight?: number,
  onClick?: () => void,
}

const CategoryCard = forwardRef<HTMLButtonElement, CategoryCardProps>(
  ({ onClick, image, imageWidth = 100, imageHeight = 100, title, ...rest }, ref) => (
    <ButtonCard {...rest} ref={ref} onClick={onClick}>
      <Stack align="center" gap="xs" w="100%">
        <Image
          src={image}
          alt={title || ''}
          width={imageWidth}
          height={imageHeight}
        />
        {title && <Text align="center">{title}</Text>}
      </Stack>
    </ButtonCard>
  ),
)

export default createPolymorphicComponent<'button', CategoryCardProps>(CategoryCard)
