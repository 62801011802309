import { Group, Text } from 'ui/core'
import {
  useConnector,
  useCurrentRefinements,
  useInstantSearch,
} from 'react-instantsearch-core'
import connectStats, {
  type StatsConnectorParams,
  type StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'

type UseStatsProps = StatsConnectorParams

export const useStats = (props?: UseStatsProps) =>
  useConnector<StatsConnectorParams, StatsWidgetDescription>(
    connectStats,
    props,
  )

const Stats = () => {
  const stats = useStats()
  const instantSearch = useInstantSearch()
  const currentRefinements = useCurrentRefinements()

  const { query } = instantSearch.indexUiState

  if (
    (!currentRefinements.items.length && !query)
    || instantSearch.status === 'stalled'
  ) {
    return <div />
  }

  return (
    <Group gap="xs">
      <Text>
        Showing{' '}
        <Text inline component="span" fw="bold">
          {stats.nbHits}{' '}
          <Text inline fw="normal" component="span">
            items
          </Text>
        </Text>
      </Text>
    </Group>
  )
}

export default Stats
