import { useEffect, useMemo, useRef } from 'react'
import {
  Icon,
  Select,
  SelectProps,
  OptionsFilter,
  ComboboxItem,
  Flex,
  Text,
} from 'ui/core'
import { Address } from 'model'
import AddressAutocompleteItem from './AddressAutocompleteItem'
import { useQueryDocuments } from '@resellam/firebase'
import { useAuth } from '@resellam/auth'
import { useModals } from 'ui/modals'
import { AddressForm } from 'core/components'
import analytics from 'lib/analytics'
import { useIsLoading } from 'core/hooks'

const ADD_NEW = 'ADD_NEW'

interface AddressAutocompleteProps
  extends Omit<SelectProps, 'data' | 'onChange' | 'value'> {
  value?: Address,
  onChange?: (address?: Address) => void,
  testID?: string,
}

const AddressAutocomplete = ({
  value,
  testID,
  onChange,
  ...rest
}: AddressAutocompleteProps) => {
  const { user } = useAuth()
  const modals = useModals()
  const onChangeRef = useRef(false)

  const {
    data: addressesData = [],
    mutate: mutateAddresses,
    isLoading: loadingAddresses,
  } = useQueryDocuments<Address>({
    collection: 'addresses',
    where: useMemo(
      () => [{ field: 'createdById', operator: '==', value: user?.id }],
      [user?.id],
    ),
    limit: 50,
    skip: !user?.id,
  })

  const handleChange = (newAddress?: Address) => {
    onChangeRef.current = true
    onChange?.(newAddress)
  }

  const primaryAddress = addressesData.find(
    (address) => address.id === user?.primaryAddressId,
  )

  useEffect(() => {
    if (primaryAddress && !onChangeRef.current) {
      handleChange(primaryAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryAddress?.id])

  const loading = useIsLoading({
    dependencies: [loadingAddresses],
  })

  const addressesMap = useMemo(
    () => new Map(addressesData.map((address) => [address.id, address])),
    [addressesData],
  )

  const optionsFilter: OptionsFilter = ({ options, search }) => {
    const splittedSearch = search.toLowerCase().trim().split(' ')

    return options
      .map((option: any) => {
        if (option.group === ' ') {
          return option
        }

        const items = (option.items as ComboboxItem[]).filter((item) => {
          const address = addressesMap.get(item.value)
          const words = [
            ...(address?.street ?? '').toLowerCase().trim().split(' '),
            ...(address?.firstName ?? '').toLowerCase().trim().split(' '),
            ...(address?.lastName ?? '').toLowerCase().trim().split(' '),
          ]

          return splittedSearch.every((searchWord) =>
            words.some((word) => word.includes(searchWord)),
          )
        })

        return { ...option, items }
      })
      .filter((option) => option.group === ' ' || option.items.length > 0)
  }

  const handleAddNewAddress = (newAddress: Address) => {
    analytics.track('add_address', {
      category: 'engagement',
    })
    mutateAddresses((data) => [...(data || []), newAddress])
    handleChange?.(newAddress)
  }

  const openAddModal = () => {
    const id = modals.openModal({
      zIndex: 201, // important when nesting inside fullscreen modal of buy now button
      title: 'Add address',
      children: (
        <AddressForm
          onSuccess={(data) => {
            modals.closeModal(id)
            handleAddNewAddress(data)
          }}
          onCancel={() => modals.closeModal(id)}
        />
      ),
    })
  }

  const data = [
    {
      group: '',
      items: addressesData.map((address) => ({
        value: address.id,
        label: address.street,
      })),
    },
    { group: ' ', items: [{ value: ADD_NEW, label: '' }] },
  ]

  return (
    <Select
      {...rest}
      value={value?.id}
      allowDeselect={false}
      withCheckIcon
      onChange={(value) => {
        if (value !== ADD_NEW) {
          handleChange?.(value ? addressesMap.get(value) : undefined)
        }
      }}
      onOptionSubmit={(value) => {
        if (value === ADD_NEW) {
          openAddModal()
        }
      }}
      searchable
      data-testid={testID}
      data-lpignore="true"
      comboboxProps={{ withinPortal: true }}
      loading={loading}
      leftSection={<Icon name="mapPin" />}
      data={data}
      renderOption={({ option }) =>
        option.value === ADD_NEW ? (
          <Flex w="100%" gap="sm" align="center">
            <Icon name="plus" />
            <Text>Add new address</Text>
          </Flex>
        ) : (
          <AddressAutocompleteItem
            address={addressesMap.get(option.value) as Address}
          />
        )}
      filter={optionsFilter}
    />
  )
}

export default AddressAutocomplete
