import { Stack, Text, Title } from 'ui/core'

export interface StepHeaderProps {
  title: string,
  subtitle?: string,
  testID?: string,
}

const StepHeader = ({ title, subtitle, testID }: StepHeaderProps) => (
  <Stack mb="md" gap={0}>
    <Title order={2} data-testid={testID}>
      {title}
    </Title>
    {subtitle && (
      <Text size="sm" color="gray">
        {subtitle}
      </Text>
    )}
  </Stack>
)

export default StepHeader
