import { Product } from 'model'
import { Stepper, Icon } from 'ui/core'
import { useEffect, useState } from 'react'
import { useDidUpdate, useWindowScroll } from 'ui/hooks'
import { formatTestID } from 'core/utils'
import { CreateSellRequest } from 'lib/sell-request'
import Photos from './Photos'
import Issues from './Issues'
import Confirmation from './Confirmation'
import { CreateSellRequestData, CreateSellRequestType } from './types'
import Details from './Details'
import { buildSellRequest } from './utils'

export interface CreateSellRequestProps {
  product: Product,
  loading?: boolean,
  testID?: string,
  fromShop?: boolean,
  onDone?: (data: CreateSellRequest) => void,
  type: CreateSellRequestType,
}

const CreateSellRequestComponent = ({
  type,
  product,
  loading,
  fromShop,
  onDone,
  testID,
}: CreateSellRequestProps) => {
  const [, scrollTo] = useWindowScroll()
  const [isSaving, setIsSaving] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [stepData, setStepData] = useState<CreateSellRequestData>({})

  useDidUpdate(() => {
    setActiveStep(0)
    setStepData({})
  }, [product?.id])

  useEffect(() => {
    if (activeStep)
      scrollTo({ y: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  const gotoStep = (increment: number) => {
    setActiveStep((current) => (current < 5 ? current + increment : current))
  }

  const prevStep = (
    name: keyof CreateSellRequestData,
    data: Record<string, any>,
  ) => {
    gotoStep(-1)
    setStepData((val) => ({ ...val, [name]: data }))
  }

  const nextStep = (
    name: keyof CreateSellRequestData,
    data: Record<string, any>,
  ) => {
    gotoStep(1)
    setStepData((val) => ({ ...val, [name]: data }))
  }

  const handleDone = async (data: CreateSellRequestData) => {
    onDone?.(await buildSellRequest(product!, data))
  }

  const allowStepSelect = (step: number) => !isSaving && activeStep > step

  if (!activeStep)
    return (
      <Details
        type={type}
        loading={loading}
        product={product}
        estimate={!fromShop}
        onNext={(data) => {
          nextStep('details', data)
        }}
        testID={formatTestID(testID, 'estimate')}
      />
    )

  return (
    <Stepper
      active={activeStep}
      onStepClick={setActiveStep}
    >
      <Stepper.Step
        allowStepSelect={allowStepSelect(0)}
        label="Details"
        icon={<Icon name="fileDescription" />}
      >
        <div />
      </Stepper.Step>
      <Stepper.Step
        allowStepSelect={allowStepSelect(1)}
        label="Photos"
        icon={<Icon name="photo" />}
      >
        <Photos
          product={product}
          initialValues={stepData.photos}
          onNext={(data) => nextStep('photos', data)}
          onBack={(data) => prevStep('photos', data)}
          testID={formatTestID(testID, 'photos')}
        />
      </Stepper.Step>
      <Stepper.Step
        allowStepSelect={allowStepSelect(2)}
        label="Issues"
        icon={<Icon name="tool" />}
      >
        <Issues
          product={product}
          initialValues={stepData.issues}
          onNext={(data) => {
            if (fromShop) {
              handleDone?.({ ...stepData, issues: data })
            } else {
              nextStep('issues', data)
            }
          }}
          onBack={(data) => prevStep('issues', data)}
          testID={formatTestID(testID, 'issues')}
        />
      </Stepper.Step>
      {!fromShop && (
        <Stepper.Completed>
          <Confirmation
            type={type}
            data={stepData}
            product={product}
            onBack={() => gotoStep(-1)}
            onSaveEnd={() => setIsSaving(false)}
            onSaveStart={() => setIsSaving(true)}
            testID={formatTestID(testID, 'confirmation')}
          />
        </Stepper.Completed>
      )}
    </Stepper>
  )
}

export default CreateSellRequestComponent
