import { useUpdateSellRequest } from '@resellam/sell-request'
import { useNotifications, useFormUI } from 'core/hooks'
import { SellRequest } from 'model'
import { Textarea } from 'ui/core'
import { z } from 'zod'

const schema = z.object({
  additionalInfo: z.string().trim().nonempty({ message: 'Additional information is required' }),
})

interface AdditionalInfoFormProps {
  sellRequest: SellRequest,
  onCancel: () => void,
  onSuccess: (additionalInfo: string) => void,
}

const AdditionalInfoForm = ({ sellRequest, onCancel, onSuccess }: AdditionalInfoFormProps) => {
  const notifications = useNotifications()
  const [updateSellRequest, updateSellRequestState] = useUpdateSellRequest()

  const { form, render } = useFormUI({
    schema,
    initialValues: {
      additionalInfo: sellRequest.additionalInfo || '',
    },
  })

  const handleSubmit = async ({ additionalInfo }: typeof form.values) => {
    const result = await updateSellRequest({ id: sellRequest.id, additionalInfo })
    notifications.show({
      variant: result ? 'success' : 'error',
      action: 'update',
      entity: 'request',
    })
    result && onSuccess(additionalInfo)
  }

  return render({
    isLoading: updateSellRequestState.isRunning,
    onSubmit: handleSubmit,
    onCancel,
    children: (
      <Textarea
        {...form.getInputProps('additionalInfo')}
        minRows={4}
        autosize
        disabled={updateSellRequestState.isRunning}
        label="Additional information"
      />
    ),
  })
}

export default AdditionalInfoForm
