import { Skeleton, Stack, ButtonCard } from 'ui/core'

interface ProductCardLoadingProps {
  imageWidth?: number,
  imageHeight?: number,
}

const ProductCardLoading = ({ imageWidth = 200, imageHeight = 200 }: ProductCardLoadingProps) => (
  <ButtonCard>
    <Stack align="center" w="100%">
      <Skeleton height={imageHeight} width={imageWidth} radius="md" />
      <Skeleton height={20} width={150} radius="xl" />
    </Stack>
  </ButtonCard>
)

export default ProductCardLoading
