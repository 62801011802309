import { useUpdateSellRequest } from '@resellam/sell-request'
import { ProductPartsField } from 'components'
import { useNotifications, useFormUI } from 'core/hooks'
import { SellRequest, SellRequestIssue } from 'model'
import { z } from 'zod'

const issueSchema = (message: string) =>
  z
    .object({
      part: z.object({
        id: z.string(),
        name: z.string(),
      }),
      description: z.string().optional(),
    })
    .array()
    .min(1, { message })
    .optional()

const schema = z
  .object({
    damages: issueSchema('Select damaged parts'),
    repairs: issueSchema('Select repaired parts'),
  })
  .strict()

interface IssuesFormProps {
  sellRequest: SellRequest,
  onCancel: () => void,
  onSuccess: (data: { repairs?: SellRequestIssue[], damages?: SellRequestIssue[] }) => void,
}

const IssuesForm = ({ sellRequest, onCancel, onSuccess }: IssuesFormProps) => {
  const notifications = useNotifications()
  const [updateSellRequest, updateSellRequestState] = useUpdateSellRequest()

  const { form, render } = useFormUI({
    schema,
    initialValues: {
      repairs: sellRequest.repairs || [],
      damages: sellRequest.damages || [],
    },
  })

  const submit = async (values: typeof form.values) => {
    const { repairs, damages } = values
    const result = await updateSellRequest({ id: sellRequest.id, repairs, damages })
    notifications.show({
      variant: result ? 'success' : 'error',
      action: 'update',
      entity: 'request',
    })
    result && onSuccess({ repairs, damages })
  }

  return render({
    onSubmit: submit,
    onCancel,
    isLoading: updateSellRequestState.isRunning,
    children: (
      <>
        <ProductPartsField
          {...form.getInputProps('damages')}
          product={sellRequest.product}
          label="Damaged parts"
        />

        <ProductPartsField
          {...form.getInputProps('repairs')}
          product={sellRequest.product}
          label="Repaired parts"
        />
      </>
    ),
  })
}

export default IssuesForm
