import { Stack, Text, ModalProps } from 'ui/core'
import { FullscreenModal } from 'core/components'
import { useQueryNotifications } from 'lib/notification'
import { NotificationCard } from 'components'

interface NotificationsModalProps extends Pick<ModalProps, 'opened' | 'onClose'> {}

const NotificationsModal = ({ opened, onClose }: NotificationsModalProps) => {
  const { data } = useQueryNotifications()

  return (
    <FullscreenModal opened={opened} onClose={onClose} title="Notifications">
      {data?.length ? (
        <Stack>
          {data?.map((notification) => (
            <NotificationCard key={notification.id} notification={notification} onClick={onClose} />
          ))}
        </Stack>
      ) : (
        <Text
          color="gray"
          size="xl"
          p="xl"
          ta="center"
        >
          No notifications
        </Text>
      )}
    </FullscreenModal>
  )
}

export default NotificationsModal
