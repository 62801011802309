import { z } from 'zod'
import { zodResolver } from 'ui/form'
import { useForm } from 'core/hooks'
import { Product, SELL_REQUEST_IMAGE_SIDES } from 'model'
import { formatTestID } from 'core/utils'
import { StepHeader, StepNavButtons } from 'components'
import UploadPhotos from '../UploadPhotos'
import { PhotosFormData } from '../types'
import { getProductTypeLabel } from '../utils'

const photoSchema = (params?: { required_error?: string }) =>
  z.object(
    {
      file: z.object({ name: z.string(), type: z.string() }).nullish(),
      isReceipt: z.boolean().default(false).nullish(),
      sellReason: z.enum(SELL_REQUEST_IMAGE_SIDES).optional(),
    },
    params,
  )

const schema = z
  .object({
    photos: z.object(
      {
        sides: photoSchema()
          .array()
          .refine(
            (val) => val.every((photo) => photo.file),
            () => ({ message: 'Upload photos of all sides' }),
          ),
        more: photoSchema().array(),
      },
      { required_error: 'Upload photos' },
    ),
  })
  .strict()

export interface PhotosProps {
  product?: Product,
  initialValues?: PhotosFormData,
  onBack: (data: PhotosFormData) => void,
  onNext: (data: PhotosFormData) => void,
  testID?: string,
}

const Photos = ({ product, initialValues, onBack, onNext, testID }: PhotosProps) => {
  const form = useForm<PhotosFormData>({
    initialValues,
    validate: zodResolver(schema),
  })

  const back = () => {
    onBack(form.values)
  }

  const next = () => {
    if (form.validate().hasErrors)
      return
    onNext(form.values)
  }

  const label = getProductTypeLabel(product)

  return (
    <div data-testid={testID}>
      <StepHeader
        title="Photos"
        subtitle={`Upload pictures of all sides of your ${label}`}
        testID={formatTestID(testID, 'photos')}
      />
      <UploadPhotos
        {...form.getInputProps('photos')}
        initialValue={form.values.photos}
        error={form.errors.photos || form.errors['photos.sides']}
        testID={formatTestID(testID, 'upload-photos')}
      />
      <StepNavButtons
        back={{ onClick: back }}
        next={{ onClick: next }}
        testID={formatTestID(testID, 'nav-buttons')}
      />
    </div>
  )
}

export default Photos
